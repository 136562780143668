import {useCallback, useState} from "react";
import axios, {AxiosError} from "axios";
import isEmpty from "lodash/isEmpty";

/**
 * Type definition for exchange rates data structure
 * Example: { "usd": { "eur": 0.85, "gbp": 0.73 } }
 */
type ExchangeRates = {
    [key: string]: {
        [key: string]: number;
    };
};

/**
 * Interface for the hook's return value
 * @property convert - Function to convert amount between currencies
 * @property getExchangeRate - Function to fetch exchange rates for a currency
 * @property isLoading - Indicates if rates are being fetched
 * @property isReady - Indicates if initial rates are loaded
 * @property error - Contains error message if any
 */
interface UseCurrencyExchangeReturn {
    convert: (amount: number, currency: string) => number;
    getExchangeRate: (currency: string) => Promise<void>;
    isLoading: boolean;
    isReady: boolean;
    error: string | null;
}

/**
 * Custom hook for currency exchange operations
 * Manages exchange rates fetching and currency conversion
 */
export const useCurrencyExchange = (): UseCurrencyExchangeReturn => {
    // State for storing the base currency for conversions
    const [baseCurrency, setBaseCurrency] = useState<string | null>(null);
    // State for storing exchange rates data
    const [exchangeRates, setExchangeRates] = useState<ExchangeRates>({});
    // States for tracking loading and error states
    const [isReady, setIsReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    /**
     * Fetches currency rates from the API
     * @param currency - The currency code in lowercase
     * @throws Error if the API response is invalid
     */
    const fetchCurrencyRates = async (currency: string) => {
        const response = await axios.get(
            `https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${currency}.json`
        );

        if (!response.data || !response.data[currency]) {
            throw new Error('Invalid response format');
        }

        return response.data[currency];
    };

    /**
     * Fetches exchange rates for a given currency
     * @param currency - The currency code to fetch rates for (e.g., "USD", "EUR")
     */
    const getExchangeRate = useCallback(async (currency: string): Promise<void> => {
        const currencyLower = currency.toLowerCase();

        // Return early if we already have rates for this currency
        if (!isEmpty(exchangeRates[currencyLower])) {
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const rates = await fetchCurrencyRates(currencyLower);
            
            // Update states with new exchange rate data
            setBaseCurrency(currencyLower);
            setExchangeRates(prev => ({
                ...prev,
                [currencyLower]: rates
            }));
            setIsReady(true);
        } catch (err) {
            // Handle errors with appropriate error message
            const errorMessage = err instanceof Error 
                ? err.message 
                : 'Failed to fetch exchange rates';
            setError(errorMessage);
            console.error('Error fetching exchange rates:', err);
        } finally {
            setIsLoading(false);
        }
    }, [exchangeRates, setBaseCurrency, setError, setExchangeRates]);

    /**
     * Converts an amount from base currency to target currency
     * @param amount - The amount to convert
     * @param currency - The target currency code
     * @returns The converted amount, or original amount if conversion not possible
     */
    const convert = useCallback((amount: number, currency: string): number => {
        // Return original amount if we don't have necessary conversion data
        if (!baseCurrency || !exchangeRates[baseCurrency]) {
            return amount;
        }
        const rate = exchangeRates[baseCurrency][currency.toLowerCase()];
        return rate ? amount / rate : amount;
    }, [baseCurrency, exchangeRates]);

    return {convert, getExchangeRate, isLoading, isReady, error};
} 